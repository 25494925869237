export default (el) => {
    loadComponents(el);

    var observer = new MutationObserver(
        mutations => mutations.forEach(mutation => handleMutation(mutation))
    );
    observer.observe(el, {
        childList: true,
        subtree: true,
    });
};

let loadedElements = new WeakMap();

function loadComponents(element) {
    element.querySelectorAll('[data-component]').forEach((el) => {
        if (loadedElements.has(el)) {
            return;
        }
        loadedElements.set(el, true);
        import('../' + el.dataset.component + '.js').then((component) => {
            component.default(el);
        });
    });
}

function handleMutation(mutation) {
    mutation.addedNodes.forEach(node => {
        if (!(node instanceof HTMLElement)) {
            return;
        }
        if (node.dataset && node.dataset.component) {
            loadComponents(node.parentNode);
        }
    });
}
